import { Hero, Layout, SEO } from "../components"

import React from "react"

const IndexPage = () => (
  <Layout>
    <SEO title="Accueil" />
    <Hero />
  </Layout>
)

export default IndexPage
